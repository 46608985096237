import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import CodeCard from './CodeCard'
import CouponCard from './CouponCard'
import iceCreamLogo from './assets/icecream.svg'
import useFetch from '../../hooks/useFetch'
import Loader from '../Loader'
import './styles.scss'

const Coupon = props => {
  const [ requestState, callApi ] = useFetch()
  const [ coupon, setCoupon ] = useState(null)
  const { loading } = requestState
  const { source, chainUuid } = props
 
  useEffect(() => {
    const url = `${process.env.GATSBY_ZSF_BASE_URL}/coupon?chain_uuid=${chainUuid}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.GATSBY_ZSF_COUPON_API_KEY
      }
    }
    callApi(url, options)
  }, [callApi, chainUuid])

  useEffect(() => {
    const { response, error } = requestState
    if(response && response.length) {
      setCoupon(response[0])
    } 
    if(error) {
      setCoupon({error})
    }
  }, [requestState])

  if(loading) {
    return (
      <div className='zsf-coupon'>
        <Loader />
      </div>
    )
  }
  if( coupon === null ) {
    return null
  }

  if(coupon.error) {
    return (
      <div className='zsf-coupon'>
        Something went wrong, please refresh the page.
      </div>
    )
  }

  return (
    <div className='zsf-coupon'>
      <div className={ `logo ${source}` } />
      <CodeCard code={coupon.code}/>
      <CouponCard
        logo= {iceCreamLogo}
        coupon={coupon}
      />
      <div className='powered-by'>Powered by <a href='/'> ZeroStorefront </a></div>
    </div>
  )
}

Coupon.propTypes = {
  source: PropTypes.string.isRequired,
  chainUuid: PropTypes.string.isRequired
}

export default Coupon
