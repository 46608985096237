import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import './styles.scss'


const showCodeCopyInfo = () => {
  toast && toast.success('Code copied to Clipboard.',
    {
      position: 'top-center',
      className: 'react-toast-container',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  )
}

/**
 * A component to render coupon code details.
 * @param {*} props 
 */
const CouponCard = (props) => {
  const { code } = props

  const onCopy = () => {
    // Copying text/coupon code on clipboard.
    // reference https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
    let input = document.createElement('textarea')
    input.innerHTML = code
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
    showCodeCopyInfo()
  }

  return (
    <div className='eg-code-card'>
      <div className='code'>{ code }</div>
      <div className='copy' onClick={onCopy}>COPY CODE</div>
    </div>
  )
}

CouponCard.propTypes = { 
  code: PropTypes.string,
}

export default CouponCard
