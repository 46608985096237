import React from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Coupon from '../components/Coupon'
import ErrorBoundary from '../components/ErrorBoundary'

const FrankPepeCoupon = () => {
  return (
    <ErrorBoundary>
      <Helmet title='Frank Pepe Coupon'/>
      <ToastContainer 
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Coupon
        source='frank-pepe'
        chainUuid={ process.env.GATSBY_CHAIN_FRANK_PEPE }
      />
    </ErrorBoundary>
  )
}

export default FrankPepeCoupon
