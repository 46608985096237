import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import './styles.scss'


/**
 * Render copuon details.
 * @param {*} props 
 */
const CouponCard = (props) => {
  const { coupon, logo } = props
  const { title, description, expiration_date, redeem_link } = coupon
  const expiryDate = moment(expiration_date)

  // Let's check if coupon is expired.
  if(expiration_date && expiryDate.diff(moment()) < 0 ) {
    return (
      <div className='eg-coupon-card'>
        <div className='subtitle'>
          Sorry, This coupon expired. We will send you another offer soon.
        </div>
      </div>
    )
  }

  const formattedExpiryDate = expiryDate.format('MM/DD/YY')
  return (
    <div className='eg-coupon-card'>
      <div className='green-bar'/>
      {!!logo && <img src={logo} alt='item' className='logo'/>  }
      <div className='title'>
        {title}
      </div>
      <div className='subtitle'>
        {description} 
        <div>Expires <span>{formattedExpiryDate}</span></div>
      </div>
      <a href={redeem_link} className='redeem'>Redeem now</a>
      <div className='expire'>
        Can only be redeemed once.<br/>
        Offer expires on <span>{formattedExpiryDate}</span>
      </div>
    </div>
  )
}

CouponCard.propTypes = { 
  logo: PropTypes.any,
  coupon: PropTypes.object.isRequired,
}

export default CouponCard
